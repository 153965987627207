import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import MultiStepForm from './MultiStepForm';
function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="p-3 mx-3 d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
          <Modal.Title id="contained-modal-title-vcenter" className='my-auto'>
            Fee Payment 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          <MultiStepForm />
          </p>
        </Modal.Body>
      </Modal>
    );
  }

export default MyVerticallyCenteredModal;