import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
 footer: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 30,
 },
});

const Footer = () => (
 <Text style={styles.footer}>This is a computer generated web payment confirmation slip</Text>
);

export default Footer;
