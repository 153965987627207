import React, { Component } from "react";
import "../App.css";

export default class Origin extends Component {
  componentWillMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/origin.webp)",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Origin &amp; History</h2>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row  m-b-50">
              <div class="col-lg-4 text-right">
                <div class="heading-text heading-section">
                  {" "}
                  <h3 class="border_needed_origin">Origin &amp; History</h3>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="row text-left">
                  <div class="col-lg-6">
                    <p>
                      Padmavathi Vidyalaya, with the motto "Excellence in
                      Education and Service before Self", have been serving the
                      Society in the field of education for the last 30 years.
                      Founded and managed by distinguished members and
                      luminaries of society, culled from the various walks of
                      life, the school has been guided on the path of progress
                      with their visionary approach &amp; lofty ideas.
                    </p>
                    <p>
                      Consequently, Padmavathi Vidyalaya has become significant
                      portal of learning and growth for young minds.
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <p>
                      {" "}
                      Our credo is to provide an all-round development and a
                      sound moral base. Fostering character building and
                      nurturing the inquisitive spirit of children, encouraging
                      scientific temperament &amp; strengthening social bonds,
                      are our constant endeavours’.
                    </p>
                    <p>
                      {" "}
                      Surging ahead with a spirit of healthy competition,
                      balancing a successful career teamed with a contented
                      life, is the gift we give to all our children.
                    </p>
                    <p>
                      {" "}
                      Guiding us in this effort of ours is Mr.RV Ramani and Mr.
                      Shiv K Dewan, Chairman Lorven Educational Society, who is
                      our constant beacon and source of strength.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </section>
      </div>
    );
  }
}
