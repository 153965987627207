import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 36,
  },
  labelContainer: {
    width: 60,
    marginRight: 10,
  },
  label: {
    textAlign: 'left',
  },
  valueContainer: {
    marginRight: 10,
  },
  value: {
    textAlign: 'left',
  },
});

const InvoiceNo = ({ invoice }) => (
  <View style={styles.container}>
    <View style={styles.labelContainer}>
      <Text style={styles.label}>Order ID:</Text>
      <Text style={styles.label}>Tracking No:</Text>
      <Text style={styles.label}>Date:</Text>
    </View>
    <View style={styles.valueContainer}>
      <Text style={styles.value}>{invoice.invoice_no}</Text>
      <Text style={styles.value}>{invoice.tracking_id}</Text>
      <Text style={styles.value}>{invoice.trans_date}</Text>
    </View>
  </View>
);

export default InvoiceNo;
