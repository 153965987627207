import React from "react";
import { useState, useEffect, useRef, useLayoutEffect, Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import $ from 'jquery';
import { Navbar, Nav, NavDropdown, Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import "./navigation.css"

const Navigation = () => {

  useEffect(() => {
    document.querySelectorAll("#collasible-nav-dropdown")
      .forEach(ele => ele.click())
  }, [])


  // render() {
  //   const { router, params, location, routes } = this.props

  return (
    <>
      <div className="navigation">
        {/* <div id="topbar" className="topbar-fullwidth light d-xl-block d-lg-block d-sm-block d-xs-block">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-none d-sm-block">
                <ul className="top-menu">
                  <li><a href="#">Phone: 93466 10414,99081 54742, 99595 79374</a></li>
                  <li><a href="#">Email: pvidyalaya@gmail.com</a></li>
                </ul>
              </div>
              <div className="col-md-6 d-sm-block text-right">
                <ul className="top-menu float-right">
                  <li><a href="https://padmavathi.huezaia.com/padmavathi/" target="_blank"><i className="fa fa-sign-in" aria-hidden="true"></i> Sign In</a></li>
                  <li style={{ 'color': '#fff !important' }}><a className="btn btn-default" href="https://padmavathi.huezaia.com/padmavathi/#/sonline" target="_blank" ><i className="fa fa-upload" aria-hidden="true"></i> Application Form</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <Header/>



        <Navbar className="navbarCustom py-0" collapseOnSelect expand="lg">
          <Navbar.Brand className="mx-auto p-0 d-block d-md-none">
            <div id="logo">
              <img className="logo" src="images/webp_images/logo.webp" alt="Hueint Logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">

              <Nav.Link className="nav-item">
                <Link to="/">
                  Home
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>
              <Nav.Link>
                <NavDropdown title="About Us" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/mission">
                      Mission & Vision
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/origin">
                      Origin & history
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/leadership">
                      Leadership
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Link>
              <Nav.Link>
                <NavDropdown title="Academics" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/learning">
                      Learning Methodology
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/cdetails">
                      The Curriculum
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/faculty">
                      Faculty
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link to="/disclosure">
                  Disclosure
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link to="/acheivement">
                  Acheivement
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>


            </Nav>
            <div className="navbar-ghost-space d-block d-md-none" />
            <Nav className="mx-3 d-none d-md-block">
              <div >
                <img className="" height={80} width={80} src="images/webp_images/logo.webp" alt="Hueint Logo" />
              </div>
            </Nav>

            <Nav className="mr-auto">
              <Nav.Link className="nav-item">
                <Link to="/gallery">
                  Gallery
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link to="/events">
                  Events
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>

              <Nav.Link>
                <NavDropdown title="Beyond Acadamics" id="collasible-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/social">
                      Social Responsibility
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/holistic">
                      Holistic Development
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/brain">
                      Brain Gym
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/transport">
                      Transport Facilities
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/sports">
                      Sports
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/relaxation">
                      Relaxation
                      <span className="sr-only">(current)</span>
                    </Link>
                  </NavDropdown.Item>

                </NavDropdown>
              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link to="/contact">
                  Contact Us
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>
              <Nav.Link className="nav-item">
                <Link to="/careers">
                  Careers
                  <span className="sr-only">(current)</span>
                </Link>

              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );

}

export default withRouter(Navigation);


{/* <Nav className="mx-3">
            <div className="" >
              <img className="logo" src="images/webp_images/logo.webp" alt="Hueint Logo" />
            </div>
            </Nav> */}
