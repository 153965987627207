import React, {Component} from 'react';
import '../App.css';
import axios from 'axios';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './events_style.css';

export default class Events extends Component{
    constructor(){
        super();
        this.state = {
            galleryfolder: [],
            error: null,
        }
    }

    getevents(){
        axios.post('https://padmavathi.huezaia.com/api/geteventwithimage', {'inst_id':'1'})
        .then(response =>  this.setState({galleryfolder: response.data.message}))
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }

    componentDidMount(){
        this.getevents();
    }
    
    render(){
        return (
            <div>
                <section id="page-title" class="text-light" style={{backgroundImage:'url(images/webp_images/events.webp)', backgroundSize:'cover', backgroundposition:'center center'}}>
                    <div class="container">
                        <div class="page-title">
                            <h2>Events</h2>
                        </div>
                    </div>
                </section>

                <section>
                <div class="container">      
                    <div class="card bg-light">
                        <div class="card-header">
                            <div class="col-lg-12 text-center">
                                <h4>Events</h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="card bg-light" id="events_append">
                            {
                                this.state.galleryfolder.length === 0 &&
                                <li>Sorry No data available</li>
                            }
                            { this.state.galleryfolder.length > 0 &&
                                this.state.galleryfolder.map( (item, index) => (

                                    <div key={index}>
                                        <div class="card-header">
                                            <div class="row">
                                                <span class="col-lg-6">
                                                    <h5>{item['folder'].title}</h5>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="card-body" style={{backgroundColor:'#fff'}}>
                                            <div class="row align-items-center">
                                                <div class="col-md-6 text-center">
                                                <div className="slide-container">
                                                    <Slide>
                                                        {
                                                        item['image'].map( (items,i) => (
                                                            <div className="each-slide" key={i}>                                                        
                                                                <img src={items.image} width="100%" alt ="image"/>
                                                              </div>
                                                                )
                                                            )
                                                        }                                                      
                                                    </Slide>
                                                  </div>

                                                
                                                    
                                                </div>
                                                <div class="col-md-6">
                                                    <p>{item['folder'].title}</p>
                                                                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                   
                       
                                ))
                            }
                                {/*<?php
                                    $array1 = array();
                                    $array2 = array();
                                    $array3=array();
                                    $sth = $pdo->prepare("SELECT * from events");    
                                    $sth->execute();
                                    $array1 = $sth->fetchAll(PDO::FETCH_ASSOC);
                                    
                                    foreach ($array1 as $key => $value) {
                                        $sths = $pdo->prepare("SELECT * from eventimage where eventname=".$value['id']);    
                                        $sths->execute();
                                        $array2=$sths->fetchAll(PDO::FETCH_ASSOC);
                                        $value=array_merge($value,array("eventimage"=>$array2));
                                        array_push($array3,$value);
                                    }
                                    //echo json_encode($array3);
                                    foreach ($array3 as $key => $value) {
                                    ?>

                                    

                                        <div class="card-header">
                                            <div class="row">
                                                <span class="col-lg-6">
                                                    <h5><?php echo $value['title']; ?></h5>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="card-body" style="background-color:#fff">
                                            <div class="row align-items-center">
                                                <div class="col-md-4 text-center">

                                                    <div class="carousel dots-inside dots-dark arrows-dark" data-items="1" data-loop="true" data-autoplay="true" data-animate-in="fadeIn" data-animate-out="fadeOut" data-autoplay-timeout="1500">
                                                    <?php foreach ($value['eventimage'] as $key => $eventimage) {
                                                        ?>
                                                        <a href="#"><img class="img-responsive loading" src="<?php echo $eventimage['image']; ?>" alt="" width="100%"></a>
                                                    <?php
                                                        }
                                                    ?>  
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-md-8">
                                                    <p><?php echo $value['description']; ?></p>
                                                    <!-- <table class="table table-condensed acheiments">
                                                        <tbody>
                                                            <tr>
                                                                <th>Name of the Student</th>
                                                                <td>Student A</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Position</th>
                                                                <td>First</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Type</th>
                                                                <td>Co Curricular</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Description</th>
                                                                <td>Description</td>
                                                            </tr>
                                                        </tbody>
                                                    </table> -->                                                
                                                </div>
                                            </div>
                                        </div>
                                        <?php }
                                  ?> */}                              
                            </div> 
                        </div>
                    </div>             
                </div>
            </section>

                
            </div>
        )
    }
}