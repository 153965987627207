import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Modal from "react-bootstrap/Modal";
import ModalDialog from "react-bootstrap/ModalDialog";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { Link } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";

export default class Home extends Component {
  showModal() {
    console.log("welcome");
    return false;
  }

  hideModal = () => {
    this.setState({ setIsOpen: false });
  };

  constructor() {
    super();
    this.state = {
      list: [],
      announcement: [],
      noticeboard: [],
      events: [],
      announcement_modal_show: false,
      noticeboard_modal_show: false,
      announcementmodeldata: [],
      noticeboardmodeldata: [],
      error: null,
    };
  }

  getlanding() {
    axios
      .post("https://padmavathi.huezaia.com/api/landingpageget", { inst_id: "1" })
      .then((response) => this.setState({ list: response.data.message }))
      .catch((error) => {
        this.setState({ errorMessage: error.message });
      });
  }

  getannouncement() {
    axios
      .post("https://padmavathi.huezaia.com/api/announcementget", { inst_id: "1" })
      .then((response) => {
        this.setState({ announcement: response.data.message });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  getnotieboard() {
    axios
      .post("https://padmavathi.huezaia.com/api/noticeget", { inst_id: "1" })
      .then((response) => {
        this.setState({ noticeboard: response.data.success });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  getevents() {
    axios
      .post("https://padmavathi.huezaia.com/api/eventsget", { inst_id: "1" })
      .then((response) => {
        this.setState({ events: response.data.message });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  componentDidMount() {
    this.getlanding();
    this.getannouncement();
    this.getnotieboard();
    this.getevents();
  }

  mstart() {
    var marquee = document.getElementById("scroll_news");
    marquee.onmouseover = function () {
      this.stop();
    };
    marquee.onmouseout = function () {
      this.start();
    };
  }
  mstart_1() {
    var marquee = document.getElementById("scroll_news_1");
    marquee.onmouseover = function () {
      this.stop();
    };
    marquee.onmouseout = function () {
      this.start();
    };
  }
  mstart_2() {
    var marquee = document.getElementById("scroll_news_2");
    marquee.onmouseover = function () {
      this.stop();
    };
    marquee.onmouseout = function () {
      this.start();
    };
  }

  handleClose() {
    this.setState({ announcement_modal_show: false });
  }
  handleShow(items) {
    this.setState({ announcementmodeldata: items });
    this.setState({ announcement_modal_show: true });
  }

  handlenoticeboardClose() {
    this.setState({ noticeboard_modal_show: false });
  }
  handlenoticeboardShow(items) {
    if (items.applyto === "1") {
      items.applyto = "All";
    } else if (items.applyto === "1") {
      items.applyto = "Parents";
    } else {
      items.applyto = "Faculty";
    }
    this.setState({ noticeboardmodeldata: items });
    this.setState({ noticeboard_modal_show: true });
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.announcement_modal_show}
          onHide={this.handleClose.bind(this)}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{this.state.announcementmodeldata.title}</h3>
            <p>{this.state.announcementmodeldata.abstraction}</p>
            <p>{this.state.announcementmodeldata.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              class="btn btn-secondary"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.noticeboard_modal_show}
          onHide={this.handlenoticeboardClose.bind(this)}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              id="blog"
              class="grid-layout post-1-columns m-b-30"
              data-item="post-item"
            >
              <div class="post-item border">
                <div class="post-item-wrap">
                  <div class="post-image">
                    <a>
                      {" "}
                      <img
                        alt=""
                        src={this.state.noticeboardmodeldata.image}
                        width="100%"
                        id="img_src"
                      />{" "}
                    </a>
                  </div>
                  <div class="post-item-description">
                    {" "}
                    <span class="post-meta-date">
                      <i class="fa fa-calendar-o"></i>{" "}
                      {this.state.noticeboardmodeldata.date}
                    </span>
                    <h3 id="nposition">
                      {this.state.noticeboardmodeldata.title}
                    </h3>
                    <p id="ndescription">
                      {" "}
                      {this.state.noticeboardmodeldata.abstraction}{" "}
                    </p>
                    <p>
                      Apply To{" "}
                      <span id="applyto">
                        {this.state.noticeboardmodeldata.applyto}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              class="btn btn-secondary"
              onClick={this.handlenoticeboardClose.bind(this)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Carousel style={{ height: "650px" }}>
          {this.state.list.length === 0 && (
            <Carousel.Item>
              <div style={{ height: "650px" }}></div>
            </Carousel.Item>
          )}
          {this.state.list.length > 0 &&
            this.state.list.map((item, index) => (
              <Carousel.Item key={index}>
                <div
                  style={{
                    height: "650px",
                    background: `url(${item.image}) no-repeat center center`,
                    backgroundSize: "cover",
                    "background-size": "100%,100%",
                  }}
                ></div>
                {/*<Carousel.Caption>
                              <h3 class="text-light">title</h3>
                              <p>{item.heading_1}</p>   
                              <p>{item.heading_2}</p>   
                               </Carousel.Caption> */}
              </Carousel.Item>
            ))}
        </Carousel>
        <section
          class="p-b-0"
          style={{
            "margin-top": "-3%",
            "padding-top": "0px",
            background: "transparent",
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div
                  class="card mb-3"
                  style={{ "background-color": "#cd5b45" }}
                >
                  <div class="card-header">
                    <h4 style={{ color: "#fff" }}>
                      <i class="fa fa-bullhorn" aria-hidden="true"></i>{" "}
                      Announcements
                    </h4>
                  </div>
                  <div class="card-body" style={{ "background-color": "#fff" }}>
                    <marquee
                      id="scroll_news"
                      behavior="scroll"
                      direction="up"
                      style={{ height: "200px" }}
                      onMouseEnter={this.mstart}
                    >
                      {this.state.announcement.length === 0 && (
                        <li>Sorry No data available</li>
                      )}
                      {this.state.announcement.length > 0 &&
                        this.state.announcement.map((items, index) => (
                          <span key={index}>
                            <h4> {items.title}</h4>
                            <p>{items.abstraction}</p>
                            <a
                              class="pull-right"
                              onClick={() => this.handleShow(items)}
                            >
                              Read More
                            </a>

                            <br />
                            <hr />
                          </span>
                        ))}
                    </marquee>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div
                  class="card mb-3"
                  style={{ "background-color": "#20C5CB" }}
                >
                  <div class="card-header">
                    <h4 style={{ color: "#fff" }}>
                      <i class="fa fa-list-alt" aria-hidden="true"></i> Notice
                      Board
                    </h4>
                  </div>
                  <div class="card-body" style={{ "background-color": "#fff" }}>
                    <marquee
                      id="scroll_news_1"
                      behavior="scroll"
                      direction="up"
                      style={{ height: "200px" }}
                      onMouseEnter={this.mstart_1}
                    >
                      {this.state.noticeboard.length === 0 && (
                        <li>Sorry No data available</li>
                      )}
                      {this.state.noticeboard.length > 0 &&
                        this.state.noticeboard.map((itemss, index) => (
                          <span key={index}>
                            <img
                              class="loading"
                              src={itemss.image}
                              alt=""
                              width="100%"
                            />
                            <h4> {itemss.title}</h4>
                            <p>{itemss.abstraction}</p>
                            <a
                              id="{itemss.id}"
                              class="pull-right"
                              onClick={() => this.handlenoticeboardShow(itemss)}
                            >
                              Read More
                            </a>
                            <br />
                            <hr />
                          </span>
                        ))}
                    </marquee>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div
                  class="card mb-3"
                  style={{ "background-color": "#35A7D6" }}
                >
                  <div class="card-header">
                    <h4 style={{ color: "#fff" }}>
                      <i class="fa fa-calendar" aria-hidden="true"></i> Events
                    </h4>
                  </div>
                  <div class="card-body" style={{ "background-color": "#fff" }}>
                    <marquee
                      id="scroll_news_2"
                      behavior="scroll"
                      direction="up"
                      style={{ height: "200px" }}
                      onMouseEnter={this.mstart_2}
                    >
                      {this.state.events.length === 0 && (
                        <li>Sorry No data available</li>
                      )}
                      {this.state.events.length > 0 &&
                        this.state.events.map((items, index) => (
                          <span key={index} onMouseEnter={this.mstop}>
                            <h4> {items.title}</h4>
                            <p>{items.abstraction}</p>
                            <a href="/events" class="pull-right">
                              Read More
                            </a>
                            <br />
                            <hr />
                          </span>
                        ))}
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="p-b-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <img
                  class="img-responsive loading"
                  width="100%"
                  height="300"
                  alt="Image"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/webp_images/index_file/school.webp"
                  }
                />
              </div>
              <div class="col-lg-7">
                <div class="heading-text heading-section mt-5">
                  <h2 class="text-right">Who We Are</h2>
                  <p>
                    <b>PADMAVATHI VIDYALAYA,</b> is a non-profit,
                    non-proprietary educational body widely recognised for its
                    progressive approach and commitment to excellence.
                  </p>
                  <p class="text-right">
                    <Link class="btn" to="/origin">
                      <i class="fa fa-tint"></i> Read more
                      <span class="sr-only">(current)</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="box-fancy section-fullwidth text-light p-b-0">
          <div class="row">
            <div class="col-lg-4" style={{ backgroundColor: "#00176c" }}>
              <h3 class="text-right border_needed_home">Mission</h3>
              <p class="text-right">
                With the motto "Excellence in Education and Service Before
                Self", we develop well rounded, confident and responsible
                individuals who aspire to achieve their full potential.
              </p>
            </div>

            <div class="col-lg-4" style={{ backgroundColor: "#084693" }}>
              <h3 class="text-right border_needed_home">Vision</h3>
              <p class="text-right">
                Schools are places where the intellectual, social, emotional,
                physical, spiritual and aesthetic development of the students is
                fostered to enhance their quality of life.{" "}
              </p>
              <p class="text-right">
                <Link class="btn" to="/mission">
                  <i class="fa fa-tint"></i> Read more
                  <span class="sr-only">(current)</span>
                </Link>
              </p>
            </div>

            <div class="col-lg-4" id="mission_image">
              <span>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/webp_images/index_file/mission.webp"
                  }
                  alt="vision"
                  class="img-responsive loading"
                  width="100%"
                  height="100%"
                />
              </span>
            </div>
          </div>
        </section>

        <section class="box-fancy section-fullwidth text-light p-b-0">
          <div class="row">
            <div class="col-lg-4" id="mission_image1">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/webp_images/index_file/learning.webp"
                }
                alt="academic"
                class="img-responsive"
                width="100%"
                height="310"
              />
            </div>

            <div style={{ backgroundColor: "#e1e1e1" }} class="col-lg-4">
              <h3 class="text-right" id="text_drk">
                Learning Methodology{" "}
              </h3>
              <p class="text-right" id="text_drk1">
                At Padmavati Vidyalaya we follow a student-centered teaching
                methodology. Teachers ease the learning by creatively preparing
                and corelating the learning with everyday life examples.
              </p>
            </div>

            <div class="col-lg-4 mission_image" id="mission_image2">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/webp_images/index_file/sports.webp"
                }
                alt="sports"
                class="img-responsive loading"
                width="100%"
                height="310"
              />
            </div>
          </div>
          <div class="row">
            <div
              style={{ backgroundColor: "#e1e1e1" }}
              class="col-lg-4 third_div"
            >
              <h3 class="text-right" id="text_drk2">
                Transport Facility
              </h3>
              <p class="text-right" id="text_drk3">
                According to us a Safe transport for every child is of paramount
                importance. At Padmavati Vidyalaya we have a fleet of school
                buses{" "}
              </p>
              <p class="text-right">
                <Link class="btn" to="/transport">
                  <i class="fa fa-tint"></i> Read more
                  <span class="sr-only">(current)</span>
                </Link>
              </p>
            </div>

            <div class="col-lg-4 second_div mission_image" id="mission_image3">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/webp_images/index_file/bus.webp"
                }
                alt="transport"
                class="img-responsive loading"
                width="100%"
                height="350"
              />
            </div>

            <div
              style={{ backgroundColor: "#e1e1e1" }}
              class="col-lg-4 first_div"
            >
              <h3 class="text-right" id="text_drk4">
                Sports Facilities
              </h3>
              <p class="text-right" id="text_drk5">
                Sports and games curriculum is an integral and compulsory part
                of the education process. Sports help to build character and
                teach the importance of
              </p>
              <p class="text-right">
                <Link class="btn" to="/sports">
                  <i class="fa fa-tint"></i> Read more
                  <span class="sr-only">(current)</span>
                </Link>
              </p>
            </div>
          </div>
        </section>

        <section
          class="text-light p-t-150 p-b-150 background-overlay loading"
          data-parallax-image="images/webp_images/index_file/parallax_1.webp"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-3">
                <div class="text-center">
                  <div class="icon">
                    <i class="fa fa-3x fa-user-plus"></i>
                  </div>
                  <div class="counter">
                    {" "}
                    <span
                      data-speed="3000"
                      data-refresh-interval="50"
                      data-to="32000"
                      data-from="600"
                      data-seperator="true"
                    ></span>{" "}
                  </div>
                  <div class="seperator seperator-small"></div>
                  <p>Students</p>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="text-center">
                  <div class="icon">
                    <i class="fa fa-3x fa-users"></i>
                  </div>
                  <div class="counter">
                    {" "}
                    <span
                      data-speed="4500"
                      data-refresh-interval="23"
                      data-to="53"
                      data-from="100"
                      data-seperator="true"
                    ></span>{" "}
                  </div>
                  <div class="seperator seperator-small"></div>
                  <p>Faculty</p>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="text-center">
                  <div class="icon">
                    <i class="fa fa-3x fa-user-circle-o"></i>
                  </div>
                  <div class="counter">
                    {" "}
                    <span
                      data-speed="3000"
                      data-refresh-interval="12"
                      data-to="100"
                      data-from="50"
                      data-seperator="true"
                    ></span>{" "}
                  </div>
                  <div class="seperator seperator-small"></div>
                  <p>Pass Percentage</p>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="text-center">
                  <div class="icon">
                    <i class="fa fa-3x fa-smile-o"></i>
                  </div>
                  <div class="counter">
                    {" "}
                    <span
                      data-speed="4550"
                      data-refresh-interval="50"
                      data-to="31"
                      data-from="48"
                      data-seperator="true"
                    ></span>{" "}
                  </div>
                  <div class="seperator seperator-small"></div>
                  <p>Years of Service</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
