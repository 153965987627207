import React, { useState, useRef, useMemo } from "react";
import { Form, Button, ProgressBar, Table, Alert, Row,Col } from "react-bootstrap";
import PaymentForm from "./PaymentForm";
import axios from "axios";

const getStudentDetails = async (
  data,
  setStudentData,
  setLoading,
  setBillingData,
  setFeeData,
  setErrorText
) => {
  setLoading(true);
  try {
    const response = await axios.post(
      "https://padmavathi.huezaia.com/payment/getStudentDetails",
      data
    );
    const studentData = response.data.result.studentData;
    const stuBillingData = response.data.result.billingData;
    const stuFeesData = response.data.result.feesData;
    const orderId = response.data.result.order_id;

    if (studentData) {
      setStudentData((state) => ({ ...state, ...studentData }));
      setBillingData((state) => ({
        ...state,
        merchant_param1: studentData.student_name,
        merchant_param2: data.admissionNo,
        merchant_param3: studentData.roll_no,
        merchant_param4: `${studentData.class_name} ${studentData.section_name}`,
        order_id: orderId,
        ...stuBillingData,
      }));
      setFeeData(stuFeesData);
      setErrorText("");
    } else {
      setErrorText("Student not found");
      throw Error("No Student Found");
    }
    // Additional logic here if needed
  } catch (error) {
    console.log("Error fetching student details:", error);
    throw error;
    // Handle error, e.g., set an error state
  } finally {
    setLoading(false);
  }
};

const handleSubmit = (setLoading) => (data) => {
  setLoading(true);
  axios
     .post("https://padmavathi.huezaia.com/payment/ccavRequestHandler", data)
     .then((response) => {
       console.log("Response", response.data.data.encryptedForm);
       const encryptedForm = response.data.data.encryptedForm;
       const wrapper = document.createElement("div");
       wrapper.innerHTML = encryptedForm;
       document.body.appendChild(wrapper.firstChild);
       document.getElementById("nonseamless").submit();
     })
     .catch((error) => {
       console.log("Error", error);
     })
     .finally(() => setLoading(false));
 };

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    dob: "2012-05-01",
    admissionNo: "2725",
  });
  const [billingData, setBillingData] = useState({});
  const [feeData, setFeeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const submitRef = useRef(null);

  const handleChildSubmit = () => {
    submitRef?.current?.click()
  }

  const submitWithLoading = handleSubmit(setLoading);

  const handleNext = async () => {
    try {

      if(step == 1){
        await getStudentDetails(
          formData,
          setFormData,
          setLoading,
          setBillingData,
          setFeeData,
          setErrorText
        );
        setStep(step + 1);
        return;
      }

      if (step == 2) {
        if(feeData?.length == 0){
          setErrorText("No Fees Found");
        } else if(feeData?.length == 1){
          setStep(step + 2);
        } else {
          setStep(step + 1);
        }

        return ;
      }

      setStep(step + 1);


    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const handlePrevious = () => {
    if(step == 4){
      if(feeData?.length == 1){
        setStep(step - 2);
        return;
  
      }
    }
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };



  const GetLoadingText = ({ text, loading }) => {
    return <>{loading ? <>Loading...</> : <>{text}</>}</>;
  };

  return (
    <>
      <ProgressBar
        className="mb-3 mt-2"
        animated
        striped
        variant="success"
        now={(step / 5) * 100}
      />
      <Step
        step={step}
        formData={formData}
        setFormData={setFormData}
        billingData={billingData}
        setBillingData={setBillingData}
        feeData={feeData}
        setFeeData={setFeeData}
        handleInputChange={handleInputChange}
        submitHandler={submitWithLoading}
        submitRef={submitRef}
      />
      {errorText && <Alert variant="danger">{errorText}</Alert>}

      <div className="d-flex justify-content-between">
        {step > 1 ? (
          <Button
            disabled={loading}
            variant="secondary"
            onClick={handlePrevious}
          >
            <GetLoadingText text="Previous" loading={loading} />
          </Button>
        ) : (
          <div />
        )}
        <div>
          {step < 5 ? (
            <Button disabled={loading} variant="primary" onClick={handleNext}>
              <GetLoadingText text="Next" loading={loading} />
            </Button>
          ) : (
            <Button
              disabled={loading}
              variant="primary"
              type="submit"
              onClick={handleChildSubmit}
            >
              <GetLoadingText text="Submit" loading={loading} />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const Step = ({
  step,
  formData,
  setFormData,
  billingData,
  setBillingData,
  feeData,
  setFeeData,
  handleInputChange,
  submitHandler,
  submitRef
}) => {
  const [selectedRadio, setSelectedRadio] = useState(0);
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
 };

 const currentFeeData = feeData?.[selectedRadio]

 const dataStore = {
  feeData: currentFeeData,
  billingData,
  formData
 }

 console.log("BILLING DSTS", billingData, dataStore)


  switch (step) {
    case 1:
      return (
        <>
          <Form.Group controlId="formStep1">
            <Form.Label>Admission No:</Form.Label>
            <Form.Control
              type="text"
              name="admissionNo"
              value={formData.admissionNo}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formStep1">
            <Form.Label>Date of Birth:</Form.Label>
            <Form.Control
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
            />
          </Form.Group>
        </>
      );
    case 2:
      return (
        <Form.Group controlId="formStep2">
          <Table bordered>
            <tbody>
              <tr>
                <td>Student Name</td>
                <td>{formData.student_name}</td>
              </tr>
              <tr>
                <td>Admission No</td>
                <td>{formData.admissionNo}</td>
              </tr>
              <tr>
                <td>Date of Birth</td>
                <td>{formData.dob}</td>
              </tr>
              <tr>
                <td>Roll No</td>
                <td>{formData.roll_no}</td>
              </tr>
              <tr>
                <td>Class and Section</td>
                <td>
                  {formData.class_name} {formData.section_name}
                </td>
              </tr>
            </tbody>
          </Table>
          {/* <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            /> */}
        </Form.Group>
      );

    case 3:
      return (
        <Form>
        <fieldset>
          <Form.Group as={Row}>
            <Form.Label as="legend" column sm={3}>
              Fee Group
            </Form.Label>
            <Col sm={9}>
              {feeData?.map((fee, index) => (
                <Form.Check
                key={fee?.id}
                type="radio"
                label={fee.title}
                name="formHorizontalRadios"
                id={`formHorizontalRadios${index}`}
                value={index}
                checked={selectedRadio == index}
                onChange={handleRadioChange}
              />
              ))}
            </Col>
          </Form.Group>
        </fieldset>
      </Form>    );
    case 4:
      return (
        <ul class="list-group mb-3">
          <li class="list-group-item d-flex justify-content-between justify-content-center lh-condensed">
            <div>
              <h6 class="my-0">{currentFeeData?.title}</h6>
              <small class="text-muted">{currentFeeData?.description}</small>
            </div>
          </li>
          {currentFeeData?.feeItems?.map((item, index) => (
            <li key={index} class="list-group-item d-flex justify-content-between lh-condensed">
            <div>
              <h6 class="my-0">{item?.fees_name}</h6>
            </div>
            <span class="text-muted">₹{item?.amount}</span>
          </li>
          ))}
          <li class="list-group-item d-flex justify-content-between bg-light">
            <span>Total</span>
            <strong>₹{currentFeeData?.totalAmount}</strong>
          </li>
        </ul>
      );

    case 5:
      return <PaymentForm onSubmit={submitHandler} billingData={billingData} submitRef={submitRef} dataStore={dataStore}/>;

    default:
      return null;
  }
};

export default MultiStepForm;
