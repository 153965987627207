import React, { Component } from "react";
import "../App.css";

export default class Sports extends Component {
  componentWillMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/sports.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Sports</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container text-left">
                  <div class="row">
                    <div
                      class="col-lg-12 animated fadeInUp visible"
                      data-animate="fadeInUp"
                      data-animate-delay="200"
                    >
                      <h3>Sports</h3>
                      <p>
                        Sports and games curriculum is an integral and
                        compulsory part of the education process. Sports help to
                        build character and teach the importance of:
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Discipline</h3>
                      <p>
                        One of the most important things in life is to learn how
                        to train yourself to know your own limits and goals. Not
                        only in sports, but also in life, having discipline
                        helps you achieve success better and faster. It also
                        teaches you that not everything will be a victory and
                        you have to work harder next time.
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Respect</h3>
                      <p>
                        Having respect for your coaches in sports helps kids
                        later on in life when dealing with adults or
                        authoritative figures.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Friendship</h3>
                      <p>
                        Some of the best friends come from playing sports
                        together and this sort of bond helps young boys and
                        girls prioritize the things that are most important to
                        them.
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Overcoming Adversity</h3>
                      <p>
                        Sports are not easy, all athletes are guaranteed to have
                        a hard time at some point in their career. Going through
                        a slump usually brings out the player’s true self.
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Leadership</h3>
                      <p>
                        Leadership skills are always implemented in player’s
                        brains which helps the kids make decisions on the fly.
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Resilience</h3>
                      <p>
                        Like overcoming adversity, resilience teaches you that
                        you can't always win. Getting past your fear and leaping
                        into uncertain situations is a skill that can
                        incessantly be applied to real life.
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <h3 class="font-weight-normal">Teamwork</h3>
                      <p>
                        Realizing that a team's success is not solely on your
                        shoulders is a lesson largely applied to sports. This
                        also carries over to the real world because working well
                        with people will always be a factor when trying to get a
                        job.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/social">Social Responsibility</a>
                        </li>
                        <li>
                          <a href="/#/holistic">Holistic Development</a>
                        </li>
                        <li>
                          <a href="/#/brain">Brain Gym</a>
                        </li>
                        <li class="active">
                          <a href="/#/sports">Sports</a>
                        </li>
                        <li>
                          <a href="/#/transport">Transport Facilities</a>
                        </li>
                        <li>
                          <a href="/#/relaxation">Relaxation Techniques</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
