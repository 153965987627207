import React, { Component } from "react";
import "../App.css";

export default class Cdetails extends Component {
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/curriculum_1.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Curriculum</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9 text-left">
                <div class="container">
                  <h3>Categories</h3>
                  <p>
                    <strong>Padmavathi Vidyalaya</strong> is affiliated to
                    Central Board of Senior Secondary Education, New Delhi.
                  </p>
                  <p>
                    {" "}
                    The School offers CBSE curriculum from Grade 1 to Grade 10
                  </p>
                  <h4>Objectives:</h4>
                  <p>
                    To give a sheltered, secure and upbeat learning conditions
                    in which all students feel regarded and esteemed.
                    Distinguish, create and praise every youngster's singularity
                    and potential in order to enable them to accomplish their
                    own best. Empower creative reasoning, a sprit of enquiry for
                    learning. Help cultivate in kids, certainty to convey and
                    express their very own thoughts. Construct dependable frame
                    of mind that will empower one to wind up dynamic and minding
                    individuals from the network on the loose.
                  </p>
                  <h4>Levels Of Curriculum:</h4>
                  <p>PRE-PRIMARY( LKG – UKG) </p>
                  <p> Primary Section ( 1 to 5) </p>
                  <p> Middle Section(6 to 8) </p>
                  <p> Secondary Section( 9 – 10) </p>
                  <p> Senior Secondary Section( 11 – 12) </p>
                  <p>
                    The Curriculum offers chances to kids to build up a rich mix
                    of scholarly, physical , social , imaginative and social
                    exercises. It is student focused, underscoring the
                    significance of dialects and in the meantime reacts to the
                    changing needs in Science , Technology, Social , Personal
                    and Health Education. It has been deliberately intended to
                    be age fitting with a reasonable blend of work and play. The
                    center subjects offered during their developmental years are
                    Languages, Mathematics, Sciences, Social Studies and
                    Computers.{" "}
                  </p>
                  <p>
                    Our essential goal at the school is to bestow training to
                    the kids by not meeting but rather beating universal
                    instructive guidelines dependent on Indian worth framework
                    which we see is the best on the planet. It is our
                    undertaking to give all encompassing getting the hang of,
                    supporting ability and bridling potential to create
                    worldwide pioneers tomorrow. We intend to be one of a kind
                    foundation giving the kids world class training with moral,
                    true, caring and positive methodology so its item are formed
                    into capable natives to address the difficulties of today
                    tomorrow.
                  </p>
                  <p>
                    An astounding learning condition for senior students,
                    instructors who are focused on counselling students to
                    accomplish most astounding potential outcomes, Students are
                    urged to invest heavily in their appearance, authority
                    openings, capacity to accomplish and to be their best, that
                    all understudies have a Mentor, custom, history, exclusive
                    standards, unlimited open doors for learning, individual
                    consideration, each open door for understudies to prosper.
                  </p>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <ul>
                          <li>
                            <a href="/#/learning">Learning Methodology</a>
                          </li>
                          <li>
                            <a href="/#/disclosure">Mandatory Disclosure</a>
                          </li>
                          <li class="active">
                            <a href="/#/cdetaails">The Curriculum</a>
                          </li>
                          <li>
                            <a href="/#/faculty">Faculty</a>
                          </li>
                        </ul>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
