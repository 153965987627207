import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import InvoiceDownload from "./PdfInvoice/InvoiceDownload";
import { useLocation } from "react-router-dom";
import qs from "qs";

function isValidInvoiceObject(obj) {
  // Check if obj is an object and not null
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  // Define the required properties and their types
  const requiredProperties = {
    invoice_no: "string",
    tracking_id: "string",
    trans_date: "string",
    company: "string",
    email: "string",
    phone: "string",
    address: "string",
    items: "object", // Assuming items is an array or object
    order_status: "string",
  };

  // Check if obj has all required properties and they are of the correct type
  for (const [key, type] of Object.entries(requiredProperties)) {
    if (!obj.hasOwnProperty(key) || typeof obj[key] !== type) {
      return false;
    }
  }

  // Additional check for items to ensure it has at least one value
  if (Array.isArray(obj.items) && obj.items.length === 0) {
    return false;
  }

  // If all checks pass, the object is valid
  return true;
}

const invoice = {
  shipping: {
    name: "John Doe",
    address: "1234 Main Street",
    city: "San Francisco",
    state: "CA",
    country: "US",
    postal_code: 94111,
  },
  items: [
    {
      item: "TC 100",
      description: "Toner Cartridge",
      quantity: 2,
      amount: 6000,
    },
    {
      item: "USB_EXT",
      description: "USB Cable Extender",
      quantity: 1,
      amount: 2000,
    },
  ],
  subtotal: 8000,
  paid: 0,
  invoice_nr: 1234,
};

// const downloadPDF = () => {
//     createInvoice(invoice, "invoice.pdf");

// }

const SuccessPayment = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="message-box _success">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            <h2> Your payment was successful </h2>
            <p>
              {" "}
              Thank you for your payment. Please download the invoice below{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FailedPayment = ({ failureMessage }) => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="message-box _success _failed">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            <h2> Your payment failed </h2>
            <p> {failureMessage} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const parseQs = (str) => qs.parse(str, { ignoreQueryPrefix: true })

function PaymentStatusModal(props) {
  const location = useLocation();
  console.log("ASASASAS", location.search)
  const parsedParams = parseQs(location.search);
  const decryptedParams = parsedParams?.invoiceDetails ? atob(parsedParams?.invoiceDetails) : null 
  const invoiceDetails = parseQs(decryptedParams);
  console.log("ASASASAS", location.search, parsedParams, invoiceDetails, decryptedParams)

  const isFailureTransaction =
  invoiceDetails?.order_status?.toLowerCase() !== "success";
  const isInvalidInvoice =
    !isValidInvoiceObject(invoiceDetails) || isFailureTransaction;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>
          {isFailureTransaction ? (
            <FailedPayment failureMessage={parsedParams?.status_message || invoiceDetails?.status_message} />
          ) : (
            <SuccessPayment />
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        {isInvalidInvoice ? (
          <Button className="m-auto" disabled={true} onClick={() => {}}>
            Receipt Unavailable
          </Button>
        ) : (
          <InvoiceDownload
            invoiceData={invoiceDetails}
            isInvalidInvoice={isInvalidInvoice}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default PaymentStatusModal;
