import React, { Component } from "react";
import "../App.css";

export default class Brain extends Component {
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/brain_gym.webp)",
            backgroundSize: "cover",
            backgroundposition: "center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Brain Gym</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container text-left">
                  <h3>Brain Gym</h3>
                  <p>
                    A simple series of exercises could help your brain function
                    better, making you sharper, smarter – and far more
                    confident. Brain Gym comprises very easy body movements
                    which have been designed to coax the two hemispheres of the
                    brain to work in synchronisation.
                  </p>
                  <p>
                    A simple series of exercises could help your brain function
                    better, making you sharper, smarter – and far more
                    confident. Brain Gym comprises very easy body movements
                    which have been designed to coax the two hemispheres of the
                    brain to work in synchronisation.
                  </p>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/social">Social Responsibility</a>
                        </li>
                        <li>
                          <a href="/#/holistic">Holistic Development</a>
                        </li>
                        <li class="active">
                          <a href="/#/brain">Brain Gym</a>
                        </li>
                        <li>
                          <a href="/#/sports">Sports</a>
                        </li>
                        <li>
                          <a href="/#/transport">Transport Facilities</a>
                        </li>
                        <li>
                          <a href="/#/relaxation">Relaxation Techniques</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
