import React, { useState } from "react";
import { Form, Row, Col, Container, Jumbotron, Button } from "react-bootstrap";
// const orderid = require('order-id')('padmavathi-orders');
import qs from "qs"

function getCurrentDomainAndPort() {
  const protocol = window.location.protocol; // This will be 'http:' or 'https:'
  const hostname = window.location.hostname;
  const port = window.location.port;
 
  // Determine the protocol to use based on the current connection
  const protocolToUse = protocol === 'https:' ? 'https' : 'http';
 
  // Check if the hostname is 'localhost' or '127.0.0.1'
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
       // If port is specified, include it in the output
       if (port) {
           return `${protocolToUse}://${hostname}:${port}`;
       }
  }
  return `${protocolToUse}://${hostname}`;
 }
 
const AddressInfoGroup = ({ formData, handleChange, title }) => {
  const keyName = title === "Billing" ? "billing" : "delivery";
  return (
    <>
      <h3>{title} Details</h3>
      <Jumbotron>
      <Container>
        <Form.Group controlId={`form${keyName}Name`}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name={`${keyName}_name`}
            value={formData[`${keyName}_name`]}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId={`form${keyName}Address`}>
          <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name={`${keyName}_address`}
              value={formData[`${keyName}_address`]}
              onChange={handleChange}
            />
        </Form.Group>

        <Row>
          <Form.Group as={Col} controlId={`form${keyName}City`}>
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name={`${keyName}_city`}
              value={formData[`${keyName}_city`]}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId={`form${keyName}State`}>
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name={`${keyName}_state`}
              value={formData[`${keyName}_state`]}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={`form${keyName}Zip`} as={Col}>
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              name={`${keyName}_zip`}
              value={formData[`${keyName}_zip`]}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId={`form${keyName}Country`} as={Col}>
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name={`${keyName}_country`}
              value={formData[`${keyName}_country`]}
              disabled
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={`form${keyName}Tel`} as={Col}>
            <Form.Label>Tel</Form.Label>
            <Form.Control
              type="text"
              name={`${keyName}_tel`}
              value={formData[`${keyName}_tel`]}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId={`form${keyName}Email`} as={Col}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"Brief description
              name={`${keyName}_email`}
              value={formData[`${keyName}_email`]}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
      </Container>
      </Jumbotron>
    </>
  );
};

const PaymentForm = ({ onSubmit, billingData, submitRef, dataStore }) => {

  const currentUrl = "https://padmavathi.huezaia.com"

  const cancelMsg = qs.stringify({status_message: "You have cancelled the transaction."})

  const [formData, setFormData] = useState({
    ...billingData,
    merchant_id: "2797287",
    amount: dataStore?.feeData?.totalAmount,
    currency: "INR",
    language: "EN",
    billing_country: "India",
    redirect_url: `${currentUrl}/payment/ccavResponseHandler`,
    cancel_url: `${currentUrl}/payment/ccavResponseHandler`,
  });

  console.log("SASASAS", billingData, formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({...formData, merchant_param5: JSON.stringify(dataStore)});
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formMerchantId">
        <Form.Control
          type="hidden"
          name="merchant_id"
          value={formData.merchant_id}
        />
      </Form.Group>
      <Form.Group controlId="formOrderId">
        <Form.Control type="hidden" name="order_id" value={formData.order_id} />
      </Form.Group>
      <Form.Group controlId="formCurrency">
        <Form.Control type="hidden" name="currency" value={formData.currency} />
      </Form.Group>
      <Form.Group controlId="formAmount">
        <Form.Control type="hidden" name="amount" value={formData.amount} />
      </Form.Group>

      <Form.Group controlId="formLanguage">
        <Form.Control type="hidden" name="language" value={formData.language} />
      </Form.Group>

      <AddressInfoGroup
        formData={formData}
        handleChange={handleChange}
        title="Billing"
      />
      <Button style={{ display: 'none' }} variant="primary" type="submit" ref={submitRef}>
        Submit 2
      </Button>
    </Form>
  );
};

export default PaymentForm;


// what if someone wants to delete a fee group after some peopl have already paid.
// should we send out mails of the invoice after fee payment?