import React, { Component } from "react";
import "../App.css";

import axios from "axios";

export default class Disclosure extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
      error: null,
    };
  }

  getcbscupdate() {
    axios
      .post("https://padmavathi.huezaia.com/api/updatesget", { inst_id: "1" })
      .then((response) => this.setState({ list: response.data.message }))
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  componentDidMount() {
    this.getcbscupdate();
  }
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage:
              "url(images/webp_images/mandatory_disclosure_1.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Mandatory Disclosure</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container">
                  <div class="accordion dark">

                    <div class="ac-item ac-active">
                      <h5 class="ac-title">CBSC Updates</h5>
                      <div class="ac-content">
                        <div
                          class="card-body"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div
                            class="card bg-light"
                            style={{ marginBottom: "1%" }}
                            id="events_append"
                          >
                            {this.state.list.length === 0 && (
                              <li>Sorry No data available</li>
                            )}
                            {this.state.list.length > 0 &&
                              this.state.list.map((item, index) => (
                                <div style={{ marginBottom: "2%" }}>
                                  <div class="card-header">
                                    <div class="row">
                                      <span class="col-lg-6">
                                        <h5>{item.title}</h5>
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="card-body"
                                    style={{ backgroundColor: "#fff" }}
                                  >
                                    <div class="row align-items-center">
                                      <div class="col-md-12">
                                        <p class="text-dark">
                                          {item.description}
                                        </p>
                                      </div>
                                      <div class="col-md-12">
                                        <a
                                          target="_blank"
                                          class="pull-right"
                                          href={item.image}
                                          download
                                        >
                                          Download
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/learning">Learning Methodology</a>
                        </li>
                        <li class="active">
                          <a href="/#/disclosure">Mandatory Disclosure</a>
                        </li>
                        <li>
                          <a href="/#/cdetails">The Curriculum</a>
                        </li>
                        <li>
                          <a href="/#/faculty">Faculty</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
