import React, { Component } from "react";
import "../App.css";

export default class Mission extends Component {
  componentWillMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/mission.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Mission & Vision</h2>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row text-left">
              <div class="col-lg-6">
                <h3>Our Vision</h3>
                <p>
                  Schools are places where the intellectual, social, emotional,
                  physical, spiritual and aesthetic development of the students
                  is fostered to enhance their quality of life. Student
                  achievement is acknowledged and celebrated within the schools
                  and the community. Students are prepared for active,
                  independent learning in an environment which fosters a spirit
                  of enquiry and keen competition.
                </p>
                <p>
                  Our vision, as a community, is to inspire a passion for
                  learning.
                </p>
              </div>
              <div class="col-lg-6">
                <h3>Our Mission</h3>
                <p>
                  With the motto "Excellence in Education and Service Before
                  Self", we develop well rounded, confident and responsible
                  individuals who aspire to achieve their full potential.
                </p>
                <p>
                  Our mission is to facilitate all students to apply their
                  acquired skills and knowledge, and to rely upon their personal
                  attributes to lead productive lives and to become contributing
                  members of the global community by providing a high-quality,
                  comprehensive, and meaningful education for all students.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
