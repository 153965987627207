import React from 'react';
import logo from './logo.svg';
import { HashRouter as Router, Route, Switch, } from "react-router-dom";
import { Navigation, Footer, Home, Mission, Origin, Leadership, Learning, Disclosure, Faculty, Acheivement, Gallerypage, Events, Social, Holistic, Brain, Transport, Sports, Relaxation, Contact, Careers, Cdetails } from "./components";
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="body-inner">
        <Router>
          <Navigation />
          <Switch>
            <Route path="/" exact component={() => <Home />} />
            <Route path="/mission" exact component={() => <Mission />} />
            <Route path="/origin" exact component={() => <Origin />} />
            <Route path="/leadership" exact component={() => <Leadership />} />
            <Route path="/learning" exact component={() => <Learning />} />
            <Route path="/disclosure" exact component={() => <Disclosure />} />
            <Route path="/faculty" exact component={() => <Faculty />} />
            <Route path="/acheivement" exact component={() => <Acheivement />} />
            <Route path="/gallery" exact component={() => <Gallerypage />} />
            <Route path="/events" exact component={() => <Events />} />
            <Route path="/social" exact component={() => <Social />} />
            <Route path="/holistic" exact component={() => <Holistic />} />
            <Route path="/brain" exact component={() => <Brain />} />
            <Route path="/transport" exact component={() => <Transport />} />
            <Route path="/sports" exact component={() => <Sports />} />
            <Route path="/relaxation" exact component={() => <Relaxation />} />
            <Route path="/contact" exact component={() => <Contact />} />
            <Route path="/careers" exact component={() => <Careers />} />
            <Route path="/cdetails" exact component={() => <Cdetails />} />

          </Switch>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
