import React, {Component} from 'react';
import axios from 'axios';
import '../App.css';

export default class Contact extends Component{

    constructor(props) {
    super(props);

    this.state = {
            username: '',
            email: '',
            subject: '',
            comments: '',  
        }

  }


  handleUsernameChange = event => {
        this.setState({
            username: event.target.value
        })
    }

    handleCommentsChange = event => {
        this.setState({
            comments: event.target.value
        })
    }

    handleEmailChange = event => {
        this.setState({
            email: event.target.value
        })
    }
    handleSubjectChange = event => {
        this.setState({
            subject: event.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        axios.post('http://localhost:3004/api/sendcontaactformimage', {'id':this.state})
        .then(response =>  {
            if(response.data.code == '200'){
                alert('Received your request we will call back shortly!');
            }else{
                alert('Email is not received. Please try again');
            }
            })
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }
    
    render(){
      const { username, email, comments, subject } = this.state

        return (
            <div>

            <section id="page-title" class="text-light" style={{backgroundImage:'url(images/webp_images/contactus.webp)', backgroundSize:'cover', backgroundposition:'center center'}}>
                <div class="container">
                    <div class="page-title">
                        <h2>Contact Us</h2>
                    </div>
                </div>
            </section>

            <section>
            <div class="container">
                <div class="row text-left">
                    <div class="col-lg-6">
                        <h3 class="text-uppercase">Get In Touch</h3>
                        <p>The most happiest time of the day!. Suspendisse condimentum porttitor cursus. Duis nec nulla
                            turpis. Nulla lacinia laoreet odio, non lacinia nisl malesuada vel. Aenean malesuada
                            fermentum bibendum.</p>

                        <p>The most happiest time of the day!. Morbi sagittis, sem quis lacinia faucibus, orci ipsum
                            gravida tortor, vel interdum mi sapien ut justo. Nulla varius consequat magna, id molestie
                            ipsum volutpat quis. A true story, that never been told!. Fusce id mi diam, non ornare orci.
                            Pellentesque ipsum erat, facilisis ut venenatis eu, sodales vel dolor.</p>


                        <div class="row m-t-40">
                            <div class="col-lg-6">
                                <address>
                                    <strong>PADMAVATHI VIDYALAYA</strong>
                                        <p>Pulivendra Village,
                                        Tada Mandal,
                                        Tada,
                                        SPSR Nellore Dist.
                                        Andhra Pradesh</p>
                                        <p>+91 93466 10414, +91 99081 54742, +91 99595 79374</p>
                                </address>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <form class="widget-contact-form">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="name">Name</label>
                                    <input type="text" aria-required="true" name="username"
                                        class="form-control required name" placeholder="Enter your Name" value={username} onChange={this.handleUsernameChange} />
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="email">Email</label>
                                    <input type="email" aria-required="true" name="email"
                                        class="form-control required email" placeholder="Enter your Email"  value={email} onChange={this.handleEmailChange} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="subject">Your Subject</label>
                                    <input type="text" name="subject" class="form-control required"
                                        placeholder="Subject..."  value={subject} aria-required="true" onChange={this.handleSubjectChange}/>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="message">Message</label>
                                <textarea type="text" name="comments" rows="5"
                                    class="form-control required" placeholder="Enter your Message" value={comments} aria-required="true" onChange={this.handleCommentsChange}></textarea>
                            </div>

                            <div class="form-group">
                            <button class="btn" type="button" id="form-submit" type="button" onClick={this.handleSubmit}><i
                                                    class="fa fa-paper-plane"></i>&nbsp;Send message</button>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </section>
        <section class="no-padding">
            {/*<script type="text/javascript" src="//maps.googleapis.com/maps/api/js?v=3.exp"></script>
            <div class="map" data-map-address="hueint.com" data-map-zoom="10" data-map-icon="images/markers/marker1.png" data-map-type="ROADMAP"></div>*/}
           


            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.7557438874496!2d80.00239241528107!3d13.672610990398683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d73a9ba3302a7%3A0x74334b49f687336e!2sPADMAVATHI%20VIDYALAYA!5e0!3m2!1sen!2sin!4v1612524427723!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{'border':'0'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </section>

            </div>
        )
    }
}