import React, { Component } from "react";
import "../App.css";
import axios from "axios";

export default class Faculty extends Component {
  constructor() {
    super();
    this.state = {
      staffs: [],
      error: null,
    };
  }

  componentDidMount() {
    axios
      .post("https://padmavathi.huezaia.com/api/staffsget", { inst_id: "1" })
      .then((response) => {
        this.setState({ staffs: response.data.success });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  }

  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage:
              "url(https://www.studyapt.com/docs/schools/1983072/logo/1983072.png)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Faculty</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="card bg-light">
                  <div class="card-header">
                    <div class="col-lg-12 text-center">
                      <h4>Leaders</h4>
                    </div>
                  </div>
                  <div class="card-body" style={{ backgroundColor: "#fff" }}>
                    <div class="bs-example">
                      <div class="row">
                        {this.state?.staffs?.length === 0 && (
                          <li>Sorry No data available</li>
                        )}
                        {this.state?.staffs?.length > 0 &&
                          this.state.staffs.map((item, index) => (
                            <div key={index}>
                              {item.position === "1" ? (
                                <div class="col-sm-4">
                                  <div class="ih-item circle colored effect3 left_to_right">
                                    <a href="#">
                                      <div class="img">
                                        <img
                                          class="img-responsive loading"
                                          src={item.image}
                                          alt="img"
                                        />
                                      </div>
                                      <div class="info">
                                        <h3>{item.name}</h3>
                                        <p>{item.designation}</p>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card bg-light">
                  <div class="card-header">
                    <div class="col-lg-12 text-center">
                      <h4>Our Mentor</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="bs-example">
                      <div class="row">
                        {this.state?.staffs?.length === 0 && (
                          <li>Sorry No data available</li>
                        )}
                        {this.state?.staffs?.length > 0 &&
                          this.state.staffs.map((item, index) => (
                            <div key={index}>
                              {item.position === "2" ? (
                                <div class="col-sm-4">
                                  <div
                                    class="ih-item circle colored effect3 left_to_right"
                                    style={{ padding: "10%" }}
                                  >
                                    <a href="#">
                                      <div class="img">
                                        <img
                                          class="img-responsive loading"
                                          src={item.image}
                                          alt="img"
                                        />
                                      </div>
                                      <div class="info">
                                        <h3>{item.name}</h3>
                                        <p>{item.designation}</p>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/learning">Learning Methodology</a>
                        </li>
                        <li>
                          <a href="/#/disclosure">Mandatory Disclosure</a>
                        </li>
                        <li>
                          <a href="/#/cdetails">The Curriculum</a>
                        </li>
                        <li class="active">
                          <a href="/#/faculty">Faculty</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
