import React, { Component } from "react";
import "../App.css";

export default class Learning extends Component {
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/learning.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Learning Methodology</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9 text-left">
                <div class="container">
                  <p>
                    At Padmavati Vidyalaya we follow a student-centered teaching
                    methodology. Teachers ease the learning by creatively
                    preparing and corelating the learning with everyday life
                    examples. Students are guided in a involved, interesting &
                    interactive approach to each subject with an great relation
                    with the teachers. Classroom sessions are developed in such
                    a way that the student comprehends and learns rather than
                    mugging.{" "}
                  </p>
                  <p>
                    Our students are active participants in the classroom as we
                    encourage the students to participate in classroom sessions
                    instead of them just learning passively. Students are
                    piloted and allowed to the freedom to explore in the
                    interested areas bringing out the best in them.
                  </p>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li class="active">
                          <a href="/#/learning">Learning Methodology</a>
                        </li>
                        <li>
                          <a href="/#/disclosure">Mandatory Disclosure</a>
                        </li>
                        <li>
                          <a href="/#/cdetails">The Curriculum</a>
                        </li>
                        <li>
                          <a href="/#/faculty">Faculty</a>
                        </li>
                        {/*<li class="active"><a href="learning.html">Learning Methodology</a></li>
                                            <li><a href="cbsccorner.php">Mandatory Disclosure</a></li>
                                            <li><a href="curriculam.html">The Curriculum</a></li>
                                            <li><a href="faculty.php">Faculty</a></li>*/}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
