import React, { Component } from "react";
import "../App.css";

export default class Transport extends Component {
  componentWillMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/transportation.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Transport Facilities</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container text-left">
                  <h3>Transport Facilities</h3>
                  <p>
                    According to us a Safe transport for every child is of
                    paramount importance. At Padmavati Vidyalaya we have a fleet
                    of school buses designed as per standards and manned by
                    trained drivers and personnel sensitized to the needs of
                    children. Drivers and bus mothers are equipped with mobile
                    phones to ensure efficiency in terms of service and better
                    communication in case of emergencies.
                  </p>
                  <p>
                    We provide transportation for students and staff in and
                    around Sulurpeta and surrounding areas. Depending on where
                    our students reside, we recommend specific bus routes to
                    provide the best school transportation options.
                  </p>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/social">Social Responsibility</a>
                        </li>
                        <li>
                          <a href="/#/holistic">Holistic Development</a>
                        </li>
                        <li>
                          <a href="/#/brain">Brain Gym</a>
                        </li>
                        <li>
                          <a href="/#/sports">Sports</a>
                        </li>
                        <li class="active">
                          <a href="/#/transport">Transport Facilities</a>
                        </li>
                        <li>
                          <a href="/#/relaxation">Relaxation Techniques</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
