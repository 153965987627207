import React, {Component} from 'react';
import '../App.css';
import axios from 'axios';

export default class Leadership extends Component{

    constructor(){
        super();
        this.state = {
            staffs: [],
            error: null,
        }
    }

    getevents(){
        axios.post('https://padmavathi.huezaia.com/api/staffsget', {'inst_id':'1'})
        .then(response =>  {this.setState({staffs: response.data.message});})
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }
    
    componentDidMount(){
        this.getevents();
    }
    
    render(){
        return (
            <div>

            <section id="page-title" class="text-light" style={{backgroundImage:'url(images/webp_images/learning.webp)', backgroundSize:'cover', backgroundposition:'top !important'}}>
            <div class="container">
                <div class="page-title">
                    <h2>Leadership</h2>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card bg-light">
                            <div class="card-header">
                                <div class="col-lg-12 text-center">
                                    <h4>People Behind the School</h4>
                                </div>
                            </div>
                            <div class="card-body" style={{backgroundColor:'#fff'}}>
                                <div class="row team-list sort-destination" data-sort-id="team">
                                    <div class="col-12 col-sm-6 col-lg-3 isotope-item development">
                                        <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                                            <span class="thumb-info-wrapper">
                                                <img src={process.env.PUBLIC_URL + '/images/webp_images/visionaries/5.webp'} class="img-fluid" alt="" />
                                                <span class="thumb-info-title">
                                                    <span class="thumb-info-inner">Mr. RV Ramani</span>
                                                    <span class="thumb-info-type">Visionary</span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                                        <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                                            <span class="thumb-info-wrapper">
                                                <img src={process.env.PUBLIC_URL + '/images/webp_images/visionaries/1.webp'} class="img-fluid" alt="" />
                                                <span class="thumb-info-title">
                                                    <span class="thumb-info-inner">Mr. Shiv K Dewan</span>
                                                    <span class="thumb-info-type">Visionary</span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3 isotope-item marketing">
                                        <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                                            <span class="thumb-info-wrapper">
                                                <img src={process.env.PUBLIC_URL + '/images/webp_images/visionaries/2.webp'} class="img-fluid" alt="" />
                                                <span class="thumb-info-title">
                                                    <span class="thumb-info-inner">Mrs. Roopa Dewan</span>
                                                    <span class="thumb-info-type">Visionary</span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3 isotope-item development">
                                        <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                                            <span class="thumb-info-wrapper">
                                                <img src={process.env.PUBLIC_URL + '/images/webp_images/visionaries/3.webp'} class="img-fluid" alt="" />
                                                <span class="thumb-info-title">
                                                    <span class="thumb-info-inner">Mr. P.R.Lakshmi Narayanan</span>
                                                    <span class="thumb-info-type">Visionary</span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="card bg-light">
                            <div class="card-header">
                                <div class="col-lg-12 text-center">
                                    <h4>Our Mentor</h4>
                                </div>
                            </div>
                            <div class="card-body" style={{backgroundColor:'#fff'}}>
                                <div class="row team-list sort-destination" data-sort-id="team">
                                    <div class="col-12 col-sm-6 col-lg-3 isotope-item design">
                                        <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                                            <span class="thumb-info-wrapper">
                                                <img src={process.env.PUBLIC_URL + '/images/webp_images/visionaries/4.webp'} class="img-fluid" alt="" />
                                                <span class="thumb-info-title">
                                                    <span class="thumb-info-inner">Mrs. Usha Bala</span>
                                                    <span class="thumb-info-type">Mentor</span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            </div>
        )
    }
}