import React, {Component} from 'react';
import '../App.css';
import axios from 'axios';

export default class Careers extends Component{

    constructor(){
        super();
        this.state = {
            careers: [],
            error: null,
        }
    }

    getcareers(){
        axios.post('https://padmavathi.huezaia.com/api/careerget', {'inst_id':'1'})
        .then(response =>  {this.setState({careers: response.data.message});})
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }
    
    componentDidMount(){
        this.getcareers();
    }
    
    render(){
        return (
            <div>

                <section id="page-title" class="text-light" style={{backgroundImage:'url(images/webp_images/careers.webp)', backgroundSize:'cover', backgroundposition:'center center'}}>
                    <div class="container">
                        <div class="page-title">
                            <h2>careers</h2>
                        </div>
                    </div>
                </section>

                <section class="page-header page-header-modern page-header-md bg-transparent m-0" style={{padding:'120px 0 !important'}}>
                    <div class="container">
                        <div class="row">

                            <div class="col-md-12 align-self-center p-static order-2 text-center">

                                <h1 class="text-dark text-10"><strong>Careers</strong></h1>
                                <span class="sub-title text-dark">We're always looking for rockstars</span>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="container">

                    <div class="row">
                        <div class="col-lg-12 text-left">
                            <div class="overflow-hidden">
                                <h2 class="font-weight-normal">Find Your <strong
                                        class="font-weight-extra-bold">Opportunity</strong></h2>
                            </div>
                            <div class="overflow-hidden">
                                <p>Every year we help thousands of our students to explore, develop and shape their futures through connecting them with hundreds of academic programs/practices to further study providers and we also teach them the way of life with social practices to evlove their future shaped by humanity, humility and respect. Every day, our devoted and proficient team make a transformation in the lives of students. Our team knows every Student by Name & by Need, which is not just a catchphrase for us - it is a fundamental belief that guides everything we do. </p>
                            </div>
                                <p>We trust in the intrinsic potential of each student and we work to provide learning opportunities and services that nurture and support. </p>
                                <p>At Padmavathi Vidyalaya we know that children progress at their own developmental pace regardless of grade level and we celebrate all aspects of student learning and growth. We have good people, growing good people.</p>
                                <p>Padmavathi Vidyalaya is pledged to triumphing and maintaining a rational and reminiscent workforce. We encourage bestowed and qualified potential applicants join us.</p>
                        </div>
                    </div>

                </div>

                <section class="section">
            <div class="container">
                <div class="col-lg-8">
                    <div class="accordion dark">
                        {
                        this.state.careers.length === 0 &&
                            <div class="ac-item">
                                    <h5 class="ac-title">No Openings Found</h5>
                                    <div class="ac-content">
                                        <p class="font-weight-normal text-3">Openings Not Found</p>
                                    </div>
                                </div>
                        }
                        { this.state.careers.length > 0 &&
                            this.state.careers  
                            .map( (item, index) => (
                                <div key={index}>
                                       <div class="ac-item">
                                    <h5 class="ac-title">{item.position}</h5>
                                    <div class="ac-content">
                                        <p class="font-weight-normal text-3">{item.description}</p>
                                        <a id={item.id} class="btn btn-xl btn-outline-primary text-2 font-weight-bold" onClick="apply_data(this.id)">Apply</a>
                                    </div>
                                    
                                </div>
                                </div>
                                                     
                            ))
                        }   
                        {/*<?PHP                
                            $result1 = $pdo->prepare("SELECT count(*) AS id FROM careers");
                            $result1->execute();
                            $custCount = $result1->fetchColumn();
                            if($custCount > 0){
                                $stmt = $pdo->prepare("select * from careers");
                                $stmt->execute();                                            
                                while ($row = $stmt->fetch(PDO::FETCH_ASSOC)) {        ?>

                                <div class="ac-item">
                                    <h5 class="ac-title"><?php echo $row['position']?></h5>
                                    <div class="ac-content">
                                        <p class="font-weight-normal text-3"><?php echo $row['description']?></p>
                                        <a id="<?php echo $row['id']; ?>" class="btn btn-xl btn-outline-primary text-2 font-weight-bold" onclick="apply_data(this.id)">Apply</a>
                                    </div>
                                    
                                </div>
                            <?php 
                                }           
                            }else{
                                ?>
                                <div class="ac-item">
                                    <h5 class="ac-title">No Openings Found</h5>
                                    <div class="ac-content">
                                        <p class="font-weight-normal text-3">Openings Not Found</p>
                                    </div>
                                </div>
                            <?php                                            
                            }                
                            ?>*/}
                    </div>
                </div>

                
            </div>
        </section>

            </div>
        )
    }
}