import React, {Component} from 'react';
import '../App.css';
import axios from 'axios';

import "react-photo-feed/library/style.css";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default class Gallerypage extends Component{
    constructor(){
        super();
        this.state = {
            galleryfolder: [],
            displayCategory: "all",
            error: null,
        }
    }

    getgalllery(){
        axios.post('https://padmavathi.huezaia.com/api/getgalllerywithimage', {'inst_id':'1'})
        .then(response =>  {
            let folders = response.data.message
            
            const updatedFolders = folders.map((data) => {
                data.image.sort((a,b) => Date.parse(b.updated) - Date.parse(a.updated))  
                return data
            })

            const getMilliSeconds = (folder) => {
                const updatedAt = folder?.updated || 0
                return Date.parse(updatedAt)
            }

            updatedFolders.sort((a, b) => getMilliSeconds(b?.image?.[0]) - getMilliSeconds(a?.image?.[0]))

            this.setState({galleryfolder: updatedFolders})
        })
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }


    
    componentDidMount(){
        this.getgalllery();
    }
    
    render(){
        return (
            <div>
                <section id="page-title" class="text-light" style={{backgroundImage:'url(images/webp_images/gallery.webp)', backgroundSize:'cover', backgroundposition:'center center'}}>
                    <div class="container">
                        <div class="page-title">
                            <h2>Gallery</h2>
                        </div>
                    </div>
                </section>


                <section>
                    <div class="container">      
                        <div class="card bg-light">
                            <div class="card-header">
                                <div class="col-lg-12 text-center">
                                    <h4>Gallery</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card bg-light" id="events_append">
                                {
                                    this.state.galleryfolder.length === 0 &&
                                    <li>Sorry No data available</li>
                                }
                                { this.state.galleryfolder.length > 0 &&
                                    this.state.galleryfolder.map( (item, index) => (

                                        <div key={index}>
                                            <div class="card-header">
                                                <div class="row">
                                                    <span class="col-lg-12">
                                                        <h5>{item['folder'].foldername}</h5>
                                                    </span>
                                                </div>
                                            </div>  

                                            <div class="card-body" style={{backgroundColor:'#fff'}}>
                                                <div class="row align-items-center">
                                                    <div class="col-md-12 text-center">
                                                    {/* <div className="slide-container"> */}
                                                        {/* <Slide> */}
                                                            {
                                                            item['image'].map( (items,i) => (                                                                
                                                                // <div className="each-slide" key={i}> 
                                                                <>  
                                                                {items.image && <span>
                                                                    <img 
                                                                        src={items.image} 
                                                                        alt ="Gallery image" 
                                                                        onError={
                                                                            ({ currentTarget }) => { 
                                                                                currentTarget.onerror = null; 
                                                                                currentTarget.alt=""
                                                                                currentTarget.src="null";
                                                                            } 
                                                                        }
                                                                        style={{margin: "5px", maxWidth: "600px", maxHeight: "300px"}}/>
                                                                </span>   }
                                                                                                                  
                                                                </>
                                                                //   </div>
                                                                    )
                                                                )
                                                            }                                                      
                                                        {/* </Slide> */}
                                                      {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                   
                           
                                    ))
                                }                           
                                </div> 
                            </div>
                        </div>             
                    </div>
                </section>
            </div>
        )
    }
}