import React, { Component } from "react";
import "../App.css";

export default class Social extends Component {
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/social_res.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title"></div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container text-left">
                  <h3>Categories</h3>
                  <p>
                    At Padmavati Vidyalaya our goal is to provide every student
                    with the highest quality education, preparing them for their
                    future in their world, so they can believe, aspire and
                    achieve. We also seek to culture the values of respect,
                    excellence, determination, inspiration, courage, friendship
                    and equality.
                  </p>
                  <p>
                    It is our aim to be recognised by our community as their
                    outstanding school. We have a relentless pursuit for
                    excellence, a passion to exceed our expectations at every
                    opportunity. We hold ourselves accountable to the highest
                    standards and strive to build and maintain effective
                    relationships with the communities and institutions with
                    which we collaborate.
                  </p>
                  <h4>Social Responsibility:</h4>
                  <strong>
                    At Padmavati Vidyalaya, social responsibility starts with
                    taking responsibility for your own behaviors.
                  </strong>
                  <p>
                    It embraces concern for the consequences of a person’s or
                    institution’s acts as they affect interests of other people
                    and the environment.
                  </p>

                  <p>
                    More particularly, social responsibility also comprises of
                    Encouraging citizenship and volunteerism to take good care
                    of the people and the earth, followed by understanding local
                    and global interdependence, Advocating environmental
                    sustainability and engrossing in and promoting rational
                    discussions.
                  </p>
                  <p>
                    Responsibility starts with each student and then extends to
                    working with individuals, groups, community, and the world.
                  </p>
                  <p>
                    It connects with our concept of environmental responsibility
                    by asking everyone to “take care” of the world: To
                    responsibly be sure that what we have today will be here in
                    the future.
                  </p>
                  <p>
                    The philosophy is embedded into the life of the school.
                    Teachers are invested in the school’s mission. Our multi-age
                    approach nurtures strong relationships.
                  </p>
                  <h5>Students are encouraged to:</h5>
                  <p>
                    <ul>
                      <li>Identify their own feelings</li>
                      <li>Identify their strengths and challenges</li>
                      <li>
                        Express themselves through sharing ideas and experiences
                        with others
                      </li>
                      <li>Make responsible personal choices</li>
                    </ul>
                  </p>

                  <h4>Environmental Education</h4>
                  <p>
                    This day and age, it seems as though more and more children
                    are disconnected from nature. However, our future leaders
                    need to be equipped for tomorrow's challenges. As teachers,
                    it is important that we prepare our children for the future
                    that they will inherit. At our school we encourage our
                    students to live a "greener" lifestyle, at home and at
                    school. We try to achieve this by teaching children at a
                    young age to be environmentally aware, by building lifelong
                    habits that could potentially make a dramatic difference in
                    the future of the earth.
                  </p>
                  <ul>
                    <li>
                      Reducing Trash, Litter & Waste: Filling awareness and
                      create alternatives to cleaning up the environment,
                      learning about landfills, and a fun detective activity.
                    </li>
                    <li>
                      Recycling Tips & Tricks: We preach Recycling Facts, the
                      "Three Rs": Reduce, Reuse, & Recycle, building a Recycling
                      Unit: Environment Activities for the Classroom, Reusing &
                      Recycling Reduces Waste: Environment Activities for the
                      Classroom, etc.
                    </li>
                    <li>
                      Minimizing Pollution: Enhance students' comprehension
                      about air pollution by using resources. "Today students
                      are tomorrows responsible citizens"
                    </li>
                    <li>
                      Resource Consumption: Consumption is the greatest threat
                      to mother earth, we teach our students on excessive
                      consumptions and their outcomes. lessons on the
                      environment.{" "}
                    </li>
                    <li>
                      Protecting Ecosystems: We extend student learning to learn
                      about ecosystems. Lessons include home projects, role
                      playing, and a nature walk to collect information about
                      ecosystems.
                    </li>
                    <li>
                      Making Your School a Greener Place: Students learn unique
                      things here such as, Planting trees, Rain water
                      harvesting, Eco friendly environment building and they
                      help make the school a better greener place.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li class="active">
                          <a href="/#/social">Social Responsibility</a>
                        </li>
                        <li>
                          <a href="/#/holistic">Holistic Development</a>
                        </li>
                        <li>
                          <a href="/#/brain">Brain Gym</a>
                        </li>
                        <li>
                          <a href="/#/sports">Sports</a>
                        </li>
                        <li>
                          <a href="/#/transport">Transport Facilities</a>
                        </li>
                        <li>
                          <a href="/#/relaxation">Relaxation Techniques</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
