import React, {Component} from 'react';
import axios from 'axios';

 export default class Footer extends Component{

  constructor(props) {
    super(props);

    this.state = {
            username: '',
            email: '',
            comments: '',  
        }

  }


  handleUsernameChange = event => {
        this.setState({
            username: event.target.value
        })
    }

    handleCommentsChange = event => {
        this.setState({
            comments: event.target.value
        })
    }

    handleEmailChange = event => {
        this.setState({
            email: event.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        axios.post('http://localhost:3004/api/sendcontaactformimage', {'id':this.state})
        .then(response =>  {
            if(response.data.code === '200'){
                alert('Received your request we will call back shortly!');
            }else{
                alert('Email is not received. Please try again');
            }
            })
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }


  render(){
      const { username, email, comments } = this.state
        return (
            <div className="footer">            

                <footer id="footer" class="inverted"> 
                    <div class="footer-content">
                        <div class="container">
                            <div class="row text-left">
                                <div class="col-md-2"></div>
                                <div class="col-lg-4  col-lg-2 col-md-4">
                                    <div class="widget widget-contact-us loading" style={{backgroundImage: `url('images/world-map-dark.png')`, backgroundPosition: '50% 20px', backgroundRepeat: 'no-repeat'}}>
                                        <h4>Padmavathi Vidyalaya Senior Secondary School</h4>
                                        <ul class="list-icon">
                                            <li><i class="fa fa-map-marker"></i> Pulivendra Village, Tada Mandal, Tada, SPSR
                                                Nellore Dist. Andhra Pradesh</li>
                                            <li><i class="fa fa-phone"></i> +91 93466 10414, +91 99081 54742, <br/>  +91 99595 79374 </li>
                                            <li><i class="far fa-envelope"></i> <a
                                                    href="mailto:first.last@example.com">pvidyalaya@gmail.com</a> </li>
                                        </ul>
                                    </div>                            
                                </div>
                                <div class="col-lg-4 col-sm-12 col-xs-12">
                                    <form>
                                            <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                                            </div>
                                            <input type="text" aria-required="true" name="username"
                                                class="form-control required name" placeholder="Enter your Name" value={username} onChange={this.handleUsernameChange} />
                                        </div>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i
                                                        class="fa fa-envelope"></i></span>
                                            </div>
                                            <input type="email" aria-required="true" name="email"
                                                class="form-control required email" placeholder="Enter your Email" value={email} onChange={this.handleEmailChange} />
                                        </div>
                                        <div class="form-group mb-2">
                                            <textarea type="text" name="comments" rows="5"
                                                class="form-control required" placeholder="Enter your Message" value={comments} aria-required="true" onChange={this.handleCommentsChange}  ></textarea>
                                        </div>                                    
                                        <div class="form-group">
                                        <button class="btn" type="button" id="form-submit" type="button" onClick={this.handleSubmit}><i
                                                    class="fa fa-paper-plane"></i>&nbsp;Send message</button>
                                        </div>
                                    </form>

                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="copyright-content">
                        <div class="container-fluid">

                            <div class="col-lg-12">
                                <div class="copyright-text text-center">
                                    © 2021 Developed and maintained by <b><a href="https://hueint.com/" target="_blank" >Hueint Private Limited</a></b>. All Rights Reserved.
                                </div>

                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}