import React, { Component } from "react";
import "../App.css";

export default class Holistic extends Component {
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/holistic.webp)",
            backgroundSize: "cover",
            backgroundposition: "center center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Holistic Development</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container text-left">
                  <h3>Holistic Development</h3>
                  <p>
                    Holistic development is a practical approach to a
                    comprehensive learning system where physical, social,
                    emotional, mind and spiritual growth of a child is taken
                    care. This style of education not only focuses on mere
                    learning but also on implementing what is learnt.
                  </p>
                  <p>
                    Childhood is a sensitive phase of one’s life. The resources,
                    and nurturing one receives during their childhood has a
                    profound impact on their blossoming into adulthood. Each
                    child has its own personality, strengths, and intrinsic
                    talents. As a result, each child has its own nurturing
                    needs. For these young minds to blossom into the best adults
                    they can be, parents and schools will have to understand
                    them thoroughly and meet their specialized needs so that
                    holistic development of their physical, mind, and emotional
                    aspects is possible.
                  </p>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/social">Social Responsibility</a>
                        </li>
                        <li class="active">
                          <a href="/#/holistic">Holistic Development</a>
                        </li>
                        <li>
                          <a href="/#/brain">Brain Gym</a>
                        </li>
                        <li>
                          <a href="/#/sports">Sports</a>
                        </li>
                        <li>
                          <a href="/#/transport">Transport Facilities</a>
                        </li>
                        <li>
                          <a href="/#/relaxation">Relaxation Techniques</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
