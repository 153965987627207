import React, {Component} from 'react';
import '../App.css';
import axios from 'axios';

export default class Acheivement extends Component{

    constructor(){
        super();
        this.state = {
            acheivement: [],
            error: null
        }
    }

    componentDidMount(){
        axios.post('https://padmavathi.huezaia.com/api/acheivementget', {'inst_id':'1'})
        .then(response =>  {
            this.setState({acheivement: response.data.message})
        })
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
    }
    
    render(){
        return (
            <div>
                <section id="page-title" class="text-light" style={{backgroundImage:'url(images/webp_images/acheivements.webp)', backgroundSize:'cover', backgroundposition:'center center'}}>
                    <div class="container">
                        <div class="page-title">
                            <h2>Acheivement</h2>
                        </div>
                    </div>
                </section>



                <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card bg-light">
                            <div class="card-header">
                                <div class="col-lg-12 text-center">
                                    <h4>Acheivements</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                {
                            this.state.acheivement.length === 0 &&
                            <li>Sorry No data available</li>
                            }
                            { this.state.acheivement.length > 0 &&
                                this.state.acheivement.map( (item, index) => (
                                    <div key={index} class="card bg-light" style={{marginBottom:'1%'}}>
                                        <div class="card-header">
                                            <div class="row">
                                                <span class="col-lg-6 text-left">
                                                    <h5>{item.cname}</h5>
                                                </span>
                                                <span class="col-lg-6 text-right">
                                                    <h5>{item.date}</h5>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row align-items-center">
                                                <div class="col-md-4 text-center">
                                                    <img width="150px" height="150px" class="img-fluid loading"
                                                        src={item.image} alt="layout styles" width="100%" />
                                                </div>
                                                <div class="col-md-8 table-responsive">
                                                    <table class="table table-condensed acheiments">
                                                        <tbody>
                                                            <tr>
                                                                <th>Name of the Student</th>
                                                                <td>{item.sname}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Position</th>
                                                                <td>{item.position}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Type</th>
                                                                <td>{item.type}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Description</th>
                                                                <td>{item.description}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                     
                                ))
                            } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </div>
        )
    }
}