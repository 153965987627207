import React, { Component } from "react";
import "../App.css";

export default class Transport extends Component {
  render() {
    return (
      <div>
        <section
          id="page-title"
          class="text-light"
          style={{
            backgroundImage: "url(images/webp_images/relaxation.webp)",
            backgroundSize: "cover",
            backgroundposition: "center",
          }}
        >
          <div class="container">
            <div class="page-title">
              <h2>Relaxation</h2>
            </div>
          </div>
        </section>

        <section id="page-content" class="sidebar-right">
          <div class="container">
            <div class="row">
              <div class="content col-lg-9">
                <div class="container text-left">
                  <h3>Relaxation Techniques</h3>
                  <p>
                    Several research studies have shown that regular practice of
                    even a few minutes of daily meditation can help improve
                    concentration with better memory and also helps sustain it.
                    Results are not instantaneous, but they’re backed
                    scientifically.
                  </p>
                  <p>
                    The power of meditation can actually increase the working
                    size of your brain. People who consistently meditate
                    experience new connections within their brains, and an
                    increased capacity to think. Meditation is a powerful
                    activity because it improves neural connections, preventing
                    the degeneration of your neurons and protects your
                    hippocampus.
                  </p>
                  <p>
                    Meditative and mind-body practices can help students reduce
                    negative emotions and their physical effects. Healthy,
                    well-rested students are more alert in class, which leads to
                    a whole chain of positive events, like increased confidence,
                    better grades and less stress.
                  </p>
                </div>
              </div>

              <div class="sidebar col-lg-3">
                <div class="widget" id="sidebar_widget">
                  <h4 class="widget-title">CATEGORIES</h4>
                  <div id="mainMenus" class="menu-lines">
                    <nav>
                      <ul>
                        <li>
                          <a href="/#/social">Social Responsibility</a>
                        </li>
                        <li>
                          <a href="/#/holistic">Holistic Development</a>
                        </li>
                        <li>
                          <a href="/#/brain">Brain Gym</a>
                        </li>
                        <li>
                          <a href="/#/sports">Sports</a>
                        </li>
                        <li>
                          <a href="/#/transport">Transport Facilities</a>
                        </li>
                        <li class="active">
                          <a href="/#/relaxation">Relaxation Techniques</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
